import { MeetProperties } from "src/models/Meet";

interface UserInvolved {
  id: string;
  name: string;
}

export interface MeetData {
  title: string;
  description: string;
  date: string;
  summary: string;
  completed?: boolean;
  users: UserInvolved[];
  listOfUsers: any;
}

export const MeetInitial: MeetData = {
  title: '',
  description: '',
  summary: '',
  date: '',
  users: [] as UserInvolved[],
  listOfUsers: [],
};

export const MeetFill = (data: MeetProperties): MeetData => {
  return {
    title: data.title,
    description: data.description,
    date: data.date,
    summary: data.summary,

    completed: data.completed,

    users:
      data.involvedUser?.map((record) => ({
        id: record.user.id,
        name: `${record.user.name} ${record.user.lastName} ${record.user.secondLastName}`,
      })) || [],

      listOfUsers: data.involvedUser
  };
};
