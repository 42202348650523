import httpClient from './HttpClient';

import { ILogin } from 'src/interfaces/Formik/ILogin';

const prefix = '/auth';

export async function signIn(credentials: ILogin) {
  return (await httpClient.post(`${prefix}/login`, { ...credentials })).data;
}

export async function getUserInfo() {
  return (await httpClient.get(`${prefix}/me`)).data;
}
