import {
  Avatar,
  IconButton,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useBoolean } from 'src/hooks/use-boolean';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { formatDate, removeHtmlTags } from 'src/utils';
import Meet from 'src/models/Meet';
import { useRouter } from 'src/routes/hook';
import { MeetUser } from 'src/models/MeetUser';

const MeetTableRowUser = ({ meet }: { meet: MeetUser }) => {
  const navigate = useRouter();
  const quickEdit = useBoolean();

  const popover = usePopover();

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{meet?.id?.split('-')[0]}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{meet?.title}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{removeHtmlTags(meet?.summary, 15)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Label
          variant="soft"
          color={
            (meet.involvedUser.at(0)?.completed && 'success') ||
            (!meet.involvedUser.at(0)?.completed && 'error') ||
            'default'
          }
        >
          {meet.involvedUser.at(0)?.completed ? 'Activo' : 'Inactivo'}
        </Label>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatDate(meet?.date)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatDate(meet?.createdAt)}</TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <Tooltip title="Editar" placement="top" arrow>
          <IconButton
            color={quickEdit.value ? 'inherit' : 'default'}
            onClick={() => navigate.push(`edit/${meet.id}`)}
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        </Tooltip>

        {/* <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton> */}
      </TableCell>

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            // confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate.push(`edit/${meet.id}`);
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>
      </CustomPopover> */}
    </TableRow>
  );
};

export default MeetTableRowUser;
