export const POST_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const POST_SORT_OPTIONS = [
  { value: 'latest', label: 'Ultimo' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Más antiguo' },
];

export const POST_STATUS_OPTIONS = [
  { value: 'Published', label: 'Publicados' },
  { value: 'Draft', label: 'No publicados' },
];
