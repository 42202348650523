import moment from 'moment';
import 'moment/locale/es';

export const formatDate = (date: string = new Date().toDateString()) => {
  moment.locale('es');
  return moment(date)
    .format('dddd, D [de] MMMM [de] YYYY')
    .replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
};

export const buildQueryString = (filters: any) => {
  let queryString = '&';

  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      const value = filters[key];
      if (Array.isArray(value)) {
        queryString += `${key}=${value.join(',')}&`;
      } else {
        queryString += `${key}=${value}&`;
      }
    }
  }

  return queryString.slice(0, -1);
};

export const isValidURL = (string: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocolo
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // dominio
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // dirección IP (v4)
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // puerto y ruta
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // cadena de consulta
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragmento
  return !!pattern.test(string);
};

export const buildQueryStringStart = (filters: any, propsFilters: string[]) => {
  let queryString = '&';

  for (const key in filters) {
    if (filters.hasOwnProperty(key) && propsFilters.includes(key)) {
      const value = filters[key];
      if (Array.isArray(value)) {
        queryString += `${key}=${value.join(',')}&`;
      } else {
        queryString += `${key}=${value}&`;
      }
    }
  }

  return queryString.slice(0, -1);
};

export const removeHtmlTags = (text: string, maxLength: number) => {
  const withoutTags = text.replace(/<[^>]*>/g, '');

  if (withoutTags.length > maxLength) {
    return withoutTags.substr(0, maxLength) + '...';
  } else {
    return withoutTags;
  }
};