import React from 'react';
import { useResponsive } from 'src/hooks/use-responsive';
import { Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import CustomTextField from 'src/components/formik/CustomTextField';
import CustomTextEditor from 'src/components/formik/CustomTextEditor';
import { LoadingButton } from '@mui/lab';
import { useFetchUsersByCoincidence } from 'src/services/UserService';
import CustomAutoCompleteMultiple from 'src/components/formik/CustomAutoCompleteMultiple';
import UsersInMeetTable from 'src/components/meet/UsersInMeetTable';
import { useLocation } from 'react-router';

const MeetForm = ({
  formik,
  isLoading,
  disabled = false,
}: {
  formik: FormikProps<any>;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const mdUp = useResponsive('up', 'md');
  const location = useLocation();

  const [coincidence, setCoincidence] = React.useState<string>('a');
  const users = useFetchUsersByCoincidence(coincidence);

  const fetchData = async () => {
    try {
      users.refetch({});
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [coincidence]);

  return (
    <Grid container>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Detalles
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Titulo, resumen, fecha...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Detalles" />}
          <Stack spacing={3} sx={{ p: 3 }}>
            <CustomTextField field="title" formik={formik} label="Título" readonly={disabled} />
            <CustomTextField
              field="date"
              formik={formik}
              label="Fecha de la junta"
              type="date"
              inputLabelProps={{ shrink: true }}
              readonly={disabled}
            />
            <CustomTextField
              field="description"
              formik={formik}
              label="Descripción"
              multiline={true}
              rows={3}
              readonly={disabled}
            />

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Resumen</Typography>
              {/* <RHFEditor simple name="description" /> */}
              <CustomTextEditor
                field="summary"
                formik={formik}
                label="Resumen de la junta"
                readonly={disabled}
              />
            </Stack>
            {!disabled && (
              <CustomAutoCompleteMultiple
                fetchData={(coincidence) => setCoincidence(coincidence)}
                records={users.data || []}
                isLoading={users.isFetching}
                errorFetch={users.isError}
                field="users"
                formik={formik}
                label="Usuarios responsables"
              />
            )}
          </Stack>
        </Card>
      </Grid>
      <br />
      {mdUp && <Grid md={4} />}
      {!location.pathname.includes('new') && (
        <Grid xs={12} md={8} sx={{ mt: 2 }}>
          <Card>
            <Stack spacing={3} sx={{ p: 3 }}>
              <UsersInMeetTable data={formik?.values?.listOfUsers ?? []} />
            </Stack>
          </Card>
        </Grid>
      )}

      <br />
      {!disabled && (
        <>
          {mdUp && <Grid md={4} />}
          <Grid xs={12} md={8} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            &nbsp;
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={isLoading}
              onClick={() => formik.handleSubmit()}
            >
              Guardar
            </LoadingButton>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default MeetForm;
