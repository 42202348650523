import React from 'react';
import { Container } from '@mui/material';
import { useSettingsContext } from '../settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
import { updateMeetMutation, useMeetById } from 'src/services/MeetService';
import { MeetData, MeetFill, MeetInitial } from 'src/interfaces/Formik/IMeet';
import { useFormik } from 'formik';
import { MeetSchema } from 'src/validation/schemas/MeetSchema';
import { useSnackbar } from 'src/components/snackbar';
import MeetForm from 'src/forms/MeetForm';

const EditMeet = () => {
  const router = useRouter();
  const { id } = useParams();
  const settings = useSettingsContext();
  const editMeet = useMeetById(String(id));
  const updateMeet = updateMeetMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [initialValues, setInitialValues] = React.useState(MeetInitial);

  const fetchMeet = async () => {
    try {
      const { data } = await editMeet.refetch({});

      if (!data) return;
      setInitialValues(MeetFill(data));
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchMeet();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: MeetSchema,
    onSubmit: async (values) => {
      delete values?.listOfUsers;
      try {
        const response = await updateMeet.mutateAsync({ id: String(id), data: values });
        enqueueSnackbar('Junta actualizada correctamente', { variant: 'success' });
        router.push('/dashboard/meet');
      } catch (error) {
        enqueueSnackbar('Error al guardar la junta', { variant: 'error' });
      }
    },
  });

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Crear nueva minuta"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Juntas',
            href: paths.dashboard.meet.root,
          },
          { name: 'Editar minuta' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <MeetForm isLoading={editMeet.isLoading || updateMeet.isPending} formik={formik} />
    </Container>
  );
};

export default EditMeet;
