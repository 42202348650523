import { FormHelperText, Grid } from '@mui/material';
import Editor from '../editor';
import { IFormikFieldProps } from 'src/interfaces/Formik/IFormikField';

const CustomTextEditor = ({
  sm = 12,
  label = '',
  required = false,
  ...props
}: IFormikFieldProps) => {
  return (
    <Grid item xs={12} sm={sm}>
      <Editor
        id={label}
        readOnly={props.readonly}
        value={props.formik.values[props.field]}
        onChange={(event) => props.formik.setFieldValue(props.field, event)}
        error={props.formik.touched[props.field] && Boolean(props.formik.errors[props.field])}
        helperText={
          props.formik.touched[props.field] && props.formik.errors[props.field] ? (
            <FormHelperText error={true}>
              <>{props.formik.errors[props.field]}</>
            </FormHelperText>
          ) : null
        }
        simple={true}
      />
    </Grid>
  );
};

export default CustomTextEditor;
