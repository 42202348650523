import axios from 'axios';

const baseURL = 'https://apicrm.fisalert.com.mx';

const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpClient.interceptors.request.use(
  async function (config) {
    const token = sessionStorage.getItem('accessToken');
    config.headers['Content-Type'] = 'application/json';

    if (token) config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        console.log('Error http', error.response.data);
        // notification("Error", "No autorizado", "danger");
      }
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject({
        status: 500,
        message: 'Error de conexión con el servidor.',
      });
    }
  }
);

export default httpClient;

const httpFormDataClient = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

httpFormDataClient.interceptors.request.use(
  async function (config) {
    const token = sessionStorage.getItem('accessToken');

    if (token) config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { httpFormDataClient };
