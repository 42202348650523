import { InputAdornment, Stack, TextField } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Iconify from 'src/components/iconify';
import { PropsIMeetTableToolBar } from 'src/interfaces/Others/IMeetTableToolBar';

const MeetTableToolbar: React.FC<PropsIMeetTableToolBar> = ({ filters, onFilters }) => {
  const [filterName, setFilterName] = useState(filters.name);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleFilterNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
  }, []);

  useEffect(() => {
    if (filters.name !== filterName) {
      setFilterName(filters.name);
    }
  }, [filters.name]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onFilters('name', filterName);
    }, 1000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [filterName]);

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <TextField
          fullWidth
          value={filterName}
          onChange={handleFilterNameChange}
          placeholder="Buscar..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />

        {/* <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton> */}
      </Stack>
    </Stack>
  );
};

export default MeetTableToolbar;
