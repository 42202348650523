import { PayloadAction, createSlice } from '@reduxjs/toolkit';
interface State<T> {
  offset: number;
  total: number;
  rowsPerOffset: number;
  sort: string;
  filters: any[];
  records: T[];
  loading: boolean;
  tableName: string;
}

const initialState = {
  offset: 0,
  total: 0,
  rowsPerOffset: 10,
  sort: 'default',
  filters: {},
  records: [],
  loading: false,
  query: '',
  active: 0,
  inactive: 0,
  totalRecords: 0,
  tableName: '',
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setTable: (state, action) => {
      state.offset = action.payload.offset;
      state.total = action.payload.total;
      state.rowsPerOffset = action.payload.rowsPerOffset;
      state.sort = action.payload.sort;
      state.filters = action.payload.filters;
      state.records = action.payload.records;
      state.loading = action.payload;
      state.totalRecords = action.payload.totalRecords;
      state.tableName = action.payload.tableName;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    setDataTable: (
      state,
      action: PayloadAction<{
        records: [];
        total: number;
        active: number;
        inactive: number;
        totalRecords: number;
        tableName: string;
      }>
    ) => {
      state.records = action.payload.records;
      state.total = action.payload.total;
      state.active = action.payload.active;
      state.inactive = action.payload.inactive;
      state.totalRecords = action.payload.totalRecords;
      state.tableName = action.payload.tableName;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerOffset = action.payload;
      state.offset = 0;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
      state.offset = 0;
    },
  },
});

export const {
  setTable,
  resetState,
  setDataTable,
  setRowsPerPage,
  setOffset,
  setTotal,
  setQuery,
  setFilters,
} = tableSlice.actions;

export default tableSlice.reducer;
