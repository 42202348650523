// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import MeetForm from 'src/forms/MeetForm';
import { useFormik } from 'formik';
import { MeetInitial } from 'src/interfaces/Formik/IMeet';
import { MeetSchema } from 'src/validation/schemas/MeetSchema';
import { saveMeetMutation } from 'src/services/MeetService';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hook';

const CreateNewMeet = () => {
  const settings = useSettingsContext();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const createMeet = saveMeetMutation();

  const formik = useFormik({
    initialValues: MeetInitial,
    validationSchema: MeetSchema,
    onSubmit: (values) => {
      delete values?.listOfUsers;
      try {
        const response = createMeet.mutate(values);
        enqueueSnackbar('Junta guardada correctamente', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error al guardar la junta', { variant: 'error' });
      } finally {
        router.push(paths.dashboard.meet.root);
      }
    },
  });

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Crear nueva minuta"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Juntas',
            href: paths.dashboard.meet.root,
          },
          { name: 'Nueva minuta' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <MeetForm isLoading={createMeet.isLoading} formik={formik} />
    </Container>
  );
};

export default CreateNewMeet;
