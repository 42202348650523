import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from './HttpClient';
import { MeetData } from 'src/interfaces/Formik/IMeet';

const prefix = '/meet';

export const saveMeetQuery = async (data: MeetData) => {
  return (await httpClient.post(`${prefix}/save`, data)).data;
};

export function saveMeetMutation(): any {
  return useMutation({
    mutationKey: ['createBrand'],
    mutationFn: saveMeetQuery,
  });
}

export const getMeetsQuery = async (limit: number, offset: number, query: string) => {
  return (await httpClient.get(`${prefix}/paginate?limit=${limit}&offset=${offset}${query || ''}`))
    .data;
};

export const getMeetsByUserQuery = async (
  limit: number,
  offset: number,
  userId: string,
  query: string
) => {
  return (
    await httpClient.get(
      `${prefix}/paginate-by-user?limit=${limit}&offset=${offset}&user=${userId}${query || ''}`
    )
  ).data;
};

export function usePaginatedMeets(limit: number, offset: number, query: string): any {
  return useQuery({
    queryKey: ['getMeets'],
    queryFn: () => getMeetsQuery(limit, offset, query),
    enabled: false,
  });
}

export function usePaginatedMeetsByUser(
  limit: number,
  offset: number,
  userId: string,
  query: string
): any {
  return useQuery({
    queryKey: ['getMeetsByUser', userId],
    queryFn: () => getMeetsByUserQuery(limit, offset, userId, query),
    enabled: false,
  });
}

export const getMeetByIdQuery = async (id: string) => {
  return (await httpClient.get(`${prefix}/${id}`)).data;
};

export function useMeetById(id: string): any {
  return useQuery({
    queryKey: ['getMeetById', id],
    queryFn: () => getMeetByIdQuery(id),
    enabled: false,
  });
}

export const updateMeetQuery = async ({ id, data }: { id: string; data: MeetData }) => {
  return (await httpClient.patch(`${prefix}/${id}`, data)).data;
};

export function updateMeetMutation() {
  return useMutation({
    mutationKey: ['updatePost'],
    mutationFn: updateMeetQuery,
  });
}

export const updateMeetPerUserQuery = async ({ id, data }: { id: string; data: {} }) => {
  return (await httpClient.patch(`${prefix}/complete-per-user/${id}`, data)).data;
};

export function updateMeetPerUserMutation() {
  return useMutation({
    mutationKey: ['updateMeetPerUser'],
    mutationFn: updateMeetPerUserQuery,
  });
}