import { useMutation, useQuery, RefetchOptions } from '@tanstack/react-query';
import httpClient from './HttpClient';

const prefix = '/user';

export const saveUser = async (data: {}) => {
  return (await httpClient.post(`${prefix}/save`, data)).data;
};

export const paginateUsers = async (limit: number, offset: number, filters?: string) => {
  return (
    await httpClient.get(`${prefix}/paginate?limit=${limit}&offset=${offset}${filters || ''}`)
  ).data;
};

export const updateUser = async (id: string, data: {}) => {
  return (await httpClient.patch(`${prefix}/${id}`, data)).data;
};

export const deleteUser = async (id: string) => {
  return (await httpClient.delete(`${prefix}/${id}`)).data;
};

export const getUser = async (id: string) => {
  return (await httpClient.get(`${prefix}/${id}`)).data;
};

export const getUsersByCoincidence = async (coincidence: string) => {
  return (await httpClient.get(`${prefix}/coincidence/${coincidence}`)).data;
};

export const useFetchUser = (id: string) => {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => getUser(id),
    enabled: false,
  });
};

export const usePaginatedUsers = (limit: number, offset: number, filters?: string) => {
  return useQuery({
    queryKey: ['paginateUsers', limit, offset, filters],
    queryFn: () => paginateUsers(limit, offset, filters),
    enabled: false,
  });
};

export const useFetchUsersByCoincidence = (coincidence: string) => {
  return useQuery({
    queryKey: ['usersByCoincidence', coincidence],
    queryFn: () => getUsersByCoincidence(coincidence),
    enabled: false,
  });
};
