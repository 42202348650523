import React, { useCallback, useEffect, useState } from 'react';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from '../table';
import { useDispatch, useSelector } from 'src/redux/store';
import { useSettingsContext } from '../settings';
import { useRouter } from 'src/routes/hook';
import { useBoolean } from 'src/hooks/use-boolean';
import { usePaginatedMeets, usePaginatedMeetsByUser } from 'src/services/MeetService';
import {
  resetState,
  setDataTable,
  setFilters,
  setOffset,
  setQuery,
} from 'src/redux/slices/tableSlice';
import {
  Button,
  Card,
  Container,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  alpha,
} from '@mui/material';
import CustomBreadcrumbs from '../custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import Iconify from '../iconify';
import { IUserTableFilterValue, IUserTableFilters } from 'src/types/user';
import Label from '../label';
import Scrollbar from '../scrollbar';
import MeetTableRow from 'src/sections/meet/MeetTableRow';
import { useAuthContext } from 'src/auth/hooks';
import MeetTableToolbar from 'src/sections/meet/MeetTableToolbar';
import { buildQueryStringStart } from 'src/utils';
import { isEqual } from 'lodash';
import MeetFilters from 'src/sections/meet/MeetFilters';
import MeetTableRowUser from 'src/sections/meet/MeetTableRowUser';

const defaultFilters = {
  name: '',
  // users: [],
  status: 'all',
};

const DEFAULT_FILTERS = ['name', 'status'];

const STATUS_OPTIONS = [
  { value: 'all', label: 'Todos' },
  { value: 'completed', label: 'Completadas' },
  { value: 'pending', label: 'Pendientes' },
];

const TABLE_HEAD = [
  { id: 'id', label: 'Id', width: 30 },
  { id: 'name', label: 'Name' },
  { id: 'description', label: 'Descripción', width: 180 },
  { id: 'status', label: 'Estatus', width: 220 },
  { id: 'date', label: 'Fecha', width: 220 },
  { id: 'createdAt', label: 'Fecha de creación', width: 100 },
  { id: '', width: 88 },
];

const MeetListViewUser = () => {
  const table = useTable();
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const [filtersMeet, setFiltersMeet] = useState(defaultFilters);
  const router = useRouter();

  const confirm = useBoolean();
  const { user } = useAuthContext();

  const { offset, rowsPerOffset, records, total, inactive, active, tableName, query, sort } =
    useSelector((state) => state.table);

  const denseHeight = table.dense ? 52 : 72;
  // const meets = usePaginatedMeets(rowsPerOffset, offset, query);
  const meetsPeerUser = usePaginatedMeetsByUser(rowsPerOffset, offset, user?.id, query);
  const canReset = !isEqual(defaultFilters, filtersMeet);

  useEffect(() => {
    fetchData();
  }, [offset, rowsPerOffset, query]);

  const fetchData = async () => {
    const { data } = await meetsPeerUser.refetch();
    console.log(data);
    data.tableName = 'meets';
    return dispatch(setDataTable(data));
  };

  useEffect(() => {
    dispatch(setOffset(0));

    const updatedFilters = {
      ...filtersMeet,
      name: filtersMeet.name,
      status: filtersMeet.status,
    };

    dispatch(setFilters(updatedFilters));

    const query = buildQueryStringStart(updatedFilters, DEFAULT_FILTERS);
    dispatch(setQuery(query));
  }, [filtersMeet]);

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      table.onResetPage();
      setFiltersMeet((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleFiltersReset = useCallback(() => {
    table.onResetPage();
    setFiltersMeet((prevState) => defaultFilters);
  }, [table]);

  return (
    <React.Fragment>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Lista de reuniones"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Reuniones', href: paths.dashboard.meet.root },
            { name: 'Lista' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filtersMeet.status}
            onChange={(e, value) => handleFilters('status', value)}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filtersMeet.status) && 'filled') ||
                      'soft'
                    }
                    color={
                      (tab.value === 'completed' && 'success') ||
                      (tab.value === 'pending' && 'error') ||
                      (tab.value === 'banned' && 'error') ||
                      'default'
                    }
                  >
                    {tab.value === 'all' && total}
                    {tab.value === 'completed' && active}
                    {tab.value === 'pending' && inactive}
                  </Label>
                }
              />
            ))}
          </Tabs>
          {canReset && (
            <>
              <br />
              <MeetFilters
                filters={filtersMeet}
                onFilters={handleFilters}
                onResetFilters={handleFiltersReset}
                results={1}
                sx={{ p: 2.5, pt: 0 }}
              />
            </>
          )}
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  onSort={(data) => {
                    table.onSort(data);
                  }}
                  omit={['inplicatedUser']}
                />
                <TableBody>
                  {tableName === 'meets' && records.map((row) => <MeetTableRowUser meet={row} />)}
                  {meetsPeerUser.isLoading && (
                    <React.Fragment>
                      {[...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      ))}
                    </React.Fragment>
                  )}

                  {Boolean(
                    !meetsPeerUser.isLoading && meetsPeerUser.isFetched && records.length === 0
                  ) && <TableNoData notFound={Boolean(meetsPeerUser.isFetched)} />}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          <TablePaginationCustom
            count={1}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MeetListViewUser;
