import React from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { IFormikFieldProps } from 'src/interfaces/Formik/IFormikField';
import { debounce } from 'lodash';

interface OptionType {
  id: string;
  name: string;
}

const CustomAutoCompleteMultiple = ({
  sm = 12,
  min = 0,
  max = 1000000,
  hidden = false,
  required = false,
  readonly = false,
  withIcon = false,
  autoFocus = false,
  multiline = false,
  rows = 1,
  icon = <></>,
  type = 'text',
  iconPosition = 'start',
  onKeyFn = () => {},
  error,
  shrink = false,
  fetchData = () => {},
  records = [],
  isLoading = true,
  errorFetch = false,
  ...props
}: IFormikFieldProps & {
  onKeyFn?: Function;
  shrink?: boolean;
  fetchData: (coincidence: string) => void;
  records: OptionType[];
  isLoading?: boolean;
  errorFetch?: boolean;
}) => {
  const [inputValue, setInputValue] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = React.useState<OptionType[]>([]);

  const debouncedSearch = debounce((searchValue: string) => {
    fetchData(searchValue);
  }, 500);

  React.useEffect(() => {
    setSelectedOptions(props.formik.initialValues[props.field] || []);
  }, [props.formik.initialValues[props.field]]);

  React.useEffect(() => {
    if (inputValue) {
      debouncedSearch(inputValue);
    }

    return () => debouncedSearch.cancel();
  }, [inputValue, debouncedSearch]);

  const loading = open && records.length === 0;

  const handleOptionChange = (event: React.ChangeEvent<{}>, newValue: OptionType[]) => {
    const uniqueOptions = newValue.filter(
      (option, index, self) => index === self.findIndex((t) => t.id === option.id)
    );
    setSelectedOptions(uniqueOptions);
    props.formik.setFieldValue(props.field, uniqueOptions);

    fetchData('a');
  };

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={records}
      value={selectedOptions}
      onChange={handleOptionChange}
      getOptionLabel={(option: OptionType) => option.name}
      filterSelectedOptions
      loadingText="Cargando..."
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading || loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={
            error ||
            (props.formik.touched[props.field] && Boolean(props.formik.errors[props.field]))
          }
          helperText={
            props.formik.touched[props.field] && props.formik.errors[props.field] ? (
              <>{props.formik.errors[props.field]}</>
            ) : null
          }
        />
      )}
      noOptionsText="Escribe para buscar"
      open={open}
      onOpen={() => {
        setOpen(true);
        debouncedSearch('a');
      }}
      onClose={() => {
        setOpen(false);
      }}
    />
  );
};

export default CustomAutoCompleteMultiple;
