import React from 'react';
import { Container, Grid } from '@mui/material';
import { useSettingsContext } from '../settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
import {
  updateMeetMutation,
  updateMeetPerUserMutation,
  updateMeetPerUserQuery,
  useMeetById,
} from 'src/services/MeetService';
import { MeetData, MeetFill, MeetInitial } from 'src/interfaces/Formik/IMeet';
import { useFormik } from 'formik';
import { MeetSchema } from 'src/validation/schemas/MeetSchema';
import { useSnackbar } from 'src/components/snackbar';
import MeetForm from 'src/forms/MeetForm';
import CompleteMeetUser from './CompleteMeetUser';
import { CompleteMeetSchema } from 'src/validation/schemas/CompleteMeetSchema';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useAuthContext } from 'src/auth/hooks';

const EditMeetUser = () => {
  const router = useRouter();
  const { id } = useParams();
  const settings = useSettingsContext();
  const editMeet = useMeetById(String(id));
  const updateMeet = updateMeetPerUserMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [initialValues, setInitialValues] = React.useState(MeetInitial);
  const [meetData, setMeetData] = React.useState({comment: "", completed: false});
  const { user } = useAuthContext();

  const fetchMeet = async () => {
    try {
      const { data } = await editMeet.refetch({});

      if (!data) return;
      setInitialValues(MeetFill(data));
      const meet = data.involvedUser.find((meet: any) => meet?.user?.id === user?.id);
      setMeetData({comment: meet?.comment, completed: meet?.completed});
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchMeet();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: MeetSchema,
    onSubmit: async (values) => {},
  });

  const updateMeetUser = useFormik({
    enableReinitialize: true,
    initialValues: meetData,
    validationSchema: CompleteMeetSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateMeet.mutateAsync({ id: String(id), data: values });
        enqueueSnackbar('Informe actualizado correctamente', { variant: 'success' });
        router.push('/dashboard/meet');
      } catch (error) {
        enqueueSnackbar('Error al actualizar tu informe', { variant: 'error' });
      }
    },
  });

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Crear nueva minuta"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Juntas',
            href: paths.dashboard.meet.root,
          },
          { name: 'Editar minuta' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <MeetForm isLoading={editMeet.isLoading || updateMeet.isPending} formik={formik} disabled />
      <br />
      <CompleteMeetUser formik={updateMeetUser} />
    </Container>
  );
};

export default EditMeetUser;
