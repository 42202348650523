import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuthContext } from 'src/auth/hooks';
import EditMeet from 'src/components/meet/EditMeet';
import EditMeetUser from 'src/components/meet/EditMeetUser';

const EditMeetPage = () => {
  const { user } = useAuthContext();
  return (
    <>
      <Helmet>
        <title> Dashboard: Nueva reunión</title>
      </Helmet>

      {user?.rol.name === 'Administrador' ? <EditMeet /> : <EditMeetUser />}
    </>
  );
};

export default EditMeetPage;
