import { FormControlLabel, Switch } from '@mui/material';
import { IFormikFieldProps } from 'src/interfaces/Formik/IFormikField';

const CustomSwitchField = ({
  sm = 12,
  label = '',
  required = false,
  sx = {},
  ...props
}: IFormikFieldProps) => {
  return (
    <>
      <FormControlLabel
        label={label}
        required={required}
        labelPlacement="end"
        control={
          <Switch
            color="primary"
            id={props.field}
            name={props.field}
            checked={props.formik.values[props.field]}
            onChange={(event) => props.formik.setFieldValue(props.field, event.target.checked)}
          />
        }
        sx={sx}
      />

      {props.formik.touched[props.field] && props.formik.errors[props.field] && (
        <div style={{ color: 'red' }}>{String(props.formik.errors[props.field])}</div>
      )}
    </>
  );
};

export default CustomSwitchField;
