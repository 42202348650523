import React from 'react';
import { useResponsive } from 'src/hooks/use-responsive';
import { Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import CustomTextField from 'src/components/formik/CustomTextField';
import CustomTextEditor from 'src/components/formik/CustomTextEditor';
import { LoadingButton } from '@mui/lab';
import { useFetchUsersByCoincidence } from 'src/services/UserService';
import CustomAutoCompleteMultiple from 'src/components/formik/CustomAutoCompleteMultiple';
import CustomSwitchField from '../formik/CustomSwitchField';

const CompleteMeetUser = ({ formik }: { formik: FormikProps<any> }) => {
  const mdUp = useResponsive('up', 'md');
  return (
    <Grid container>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Completar pendiente
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Comentarios, pendiente finalizado...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Completar pendiente" />}
          <Stack spacing={3} sx={{ p: 3 }}>
            <CustomTextField
              field="comment"
              formik={formik}
              label="Comentarios de la tarea"
              placeholder="Escribe los comentarios de la tarea"
              multiline={true}
              rows={3}
            />

            <CustomSwitchField formik={formik} field="completed" label={'Estatus'} />
          </Stack>
        </Card>
      </Grid>

      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        &nbsp;
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          Guardar
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default CompleteMeetUser;
