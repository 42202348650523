// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useDispatch, useSelector } from 'src/redux/store';
import { resetState, setFilters, setOffset, setQuery, setTotal } from 'src/redux/slices/tableSlice';
import { buildQueryString } from 'src/utils';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  omit?: string[];
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
  omit = [],
}: Props) {
  const dispatch = useDispatch();
  const { query, filters } = useSelector((state) => state.table);

  return (
    <TableHead sx={sx}>
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={!!numSelected && numSelected < rowCount}
              checked={!!rowCount && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={order}
            sx={{ width: headCell.width, minWidth: headCell.minWidth }}
          >
            {omit.includes(headCell.id) ? (
              headCell.label
            ) : (
              <>
                {' '}
                {onSort ? (
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={() => {
                      dispatch(setTotal(0));
                      dispatch(setOffset(0));

                      const isSameColumn = orderBy === headCell.id;
                      const orderByQuery = isSameColumn
                        ? order === 'asc'
                          ? 'desc'
                          : 'asc'
                        : 'asc';

                      const sort = headCell.id;
                      const updatedFilters = {
                        ...filters,
                        sort,
                        order: orderByQuery,
                      };

                      dispatch(setFilters(updatedFilters));

                      const query = buildQueryString(updatedFilters);
                      dispatch(setQuery(query));

                      onSort(headCell.id);
                    }}
                  >
                    {headCell.label}

                    {orderBy === headCell.id ? (
                      <Box sx={{ ...visuallyHidden }}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
