import React from 'react';
import { userInvolved } from 'src/models/Meet';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { formatDate } from 'src/utils';
import Label from '../label';

const UsersInMeetTable = ({ data }: { data: userInvolved[] }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Estatus</TableCell>
            <TableCell>Comentario</TableCell>
            <TableCell>Fecha de actualización</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((record, index) => (
            <TableRow key={index}>
              <TableCell>{`${record.user.name} ${record.user.lastName} ${record.user.secondLastName}`}</TableCell>
              <TableCell>
                <Label
                  variant="soft"
                  color={
                    (record.completed && 'success') || (record.completed && 'error') || 'default'
                  }
                >
                  {record.completed ? 'Completado' : 'Pendiente'}
                </Label>
              </TableCell>
              <TableCell>{record.comment}</TableCell>
              <TableCell>{record.dateCompleted ? formatDate(record.dateCompleted) : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersInMeetTable;
