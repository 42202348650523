import { InputAdornment, TextField } from '@mui/material';

import { IFormikFieldProps } from 'src/interfaces/Formik/IFormikField';

const CustomTextField = ({
  sm = 12,
  min = 0,
  max = 1000000,
  hidden = false,
  required = false,
  readonly = false,
  withIcon = false,
  autoFocus = false,
  multiline = false,
  fullWidth = false,
  rows = 1,
  icon = <></>,
  type = 'text',
  iconPosition = 'start',
  onKeyFn = () => {},
  error,
  shrink = true,
  ...props
}: IFormikFieldProps & {
  onKeyFn?: Function;
  shrink?: boolean;
  fullWidth?: boolean;
}) => {
  return (
    <>
      <TextField
        multiline={multiline}
        rows={rows}
        fullWidth={fullWidth}
        placeholder={props.placeholder}
        type={type}
        hidden={hidden}
        required={required}
        autoFocus={autoFocus}
        id={props.field}
        name={props.field}
        label={props.label}
        onBlur={props.formik.handleBlur}
        onKeyUp={(event) => onKeyFn(event)}
        value={props.formik.values[props.field]}
        error={
          error || (props.formik.touched[props.field] && Boolean(props.formik.errors[props.field]))
        }
        onChange={(event) => {
          if (type === 'number') {
            const parsed = parseInt(event.target.value);
            const finalParsed = isNaN(parsed) ? 0 : parsed;

            if (finalParsed < min) {
              event.target.value = '0';
            } else if (finalParsed > max) {
              event.target.value = String(max);
            }
          }

          props.formik.handleChange(event);
        }}
        helperText={
          props.formik.touched[props.field] && Boolean(props.formik.errors[props.field]) ? (
            <>{props.formik.errors[props.field]}</>
          ) : null
        }
        InputProps={
          props.inputProps || {
            readOnly: readonly,
            disabled: readonly,
            // disableUnderline: readonly,
            inputProps: {
              min: min,
              max: max,
              style: { cursor: readonly ? 'not-allowed' : 'inherit' },
            },
            ...(withIcon &&
              (iconPosition === 'start'
                ? {
                    startAdornment: <InputAdornment position={iconPosition}>{icon}</InputAdornment>,
                  }
                : {
                    endAdornment: <InputAdornment position={iconPosition}>{icon}</InputAdornment>,
                  })),
          }
        }
        
        InputLabelProps={props.inputLabelProps || {}}
      />
    </>
  );
};

export default CustomTextField;
