import { Helmet } from 'react-helmet-async';
import { useAuthContext } from 'src/auth/hooks';
import MeetListView from 'src/components/meet/MeetListView';
import MeetListViewUser from 'src/components/meet/MeetListViewUser';
import UserMeetTable from 'src/components/meet/UserMeetTable';

const ListMeets = () => {
  const { user } = useAuthContext();

  return (
    <>
      <Helmet>
        <title> Dashboard: Reuniones</title>
      </Helmet>
      {user?.rol.name === 'Administrador' ? <MeetListView /> : <MeetListViewUser />}
    </>
  );
};

export default ListMeets;
