import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export default function Router() {
  // user del slice
  const { user } = useAuthContext();

  const checkPermissions = (permissions: string[]): boolean => {
    if (!permissions || permissions.length === 0) return true;
    if (!user?.rol.permissions || user.rol.permissions.length === 0) return false;

    if (user?.rol?.name === 'Administrador') return true;

    return permissions.some((permission) => user.rol.permissions.includes(permission));
  };
  //
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      // children: [{ element: <HomePage />, index: true }],
      children: [{ element: <Navigate to="/login" replace />, index: true }],
      
    },

    // Auth routes
    ...authRoutes,
    // authRoutes.map((route) =>
    //   route.roles.permissions.contain(
    //     user.role.name
    //       ? { ...route, element: <route.element /> }
    //       : { ...route, element: <Navigate to="/404" replace /> }
    //   )
    // ),

    // ...authDemoRoutes,

    // Dashboard routes
    // ...dashboardRoutes,

    // // Dashboard routes
    ...dashboardRoutes.map((route) => ({
      ...route,
      children: route.children.map((child) => {
        const permissions = child.permissions || [];
        if (permissions.length === 0 || checkPermissions(permissions)) {
          return child;
        }

        return {
          ...child,
          element: <Navigate to="/404" replace />,
        };
      }),
    })),

    // Main routes
    ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
