import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import {
  AuthGuard,
  // PermissionBasedGuard,
  //  RoleBasedGuard
} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import NewMeet from 'src/pages/dashboard/meet/NewMeet';
import ListMeets from 'src/pages/dashboard/meet/ListMeets';
import EditMeetPage from 'src/pages/dashboard/meet/EditMeet';
// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// CATEGORY
const CategoryListPage = lazy(() => import('src/pages/dashboard/category/list'));
const CategoryCreatePage = lazy(() => import('src/pages/dashboard/category/new'));
const CategoryEditPage = lazy(() => import('src/pages/dashboard/category/edit'));
// CAROUSEL
const CarouselListPage = lazy(() => import('src/pages/dashboard/carousel/list'));
const CarouselCreatePage = lazy(() => import('src/pages/dashboard/carousel/new'));
const CarouselEditPage = lazy(() => import('src/pages/dashboard/carousel/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// ROlE
const RoleListPage = lazy(() => import('src/pages/dashboard/role/list'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/role/new'));
const RoleEditPage = lazy(() => import('src/pages/dashboard/role/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { permissions: [], element: <></>, index: true },
      // { path: 'ecommerce', element: <OverviewEcommercePage /> },
      // { path: 'analytics', element: <OverviewAnalyticsPage /> },
      // { path: 'banking', element: <OverviewBankingPage /> },
      // { path: 'booking', element: <OverviewBookingPage /> },
      // { path: 'file', element: <OverviewFilePage /> },

      // ******* usuarios *******
      {
        path: 'user',
        permissions: ['Usuarios'],
        // element: (
        //   <PermissionBasedGuard permissions={['Usuarios']}>
        //     {/* <Suspense fallback={<LoadingScreen />}> */}
        //     <Outlet />
        //     {/* </Suspense> */}
        //   </PermissionBasedGuard>
        // ),
        children: [
          { element: <UserListPage />, index: true },
          { path: 'profile', element: <></> },
          // { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: 'edit/:id', element: <UserEditPage /> },
          { path: 'account', element: <></>, validRoles: ['Admin'] },
          // { path: 'account', element: <UserAccountPage />, validRoles: ['Admin'] },
        ],
      },

      // {
      //   path: 'category',
      //   permissions: ['Categorías'],
      //   children: [
      //     { element: <CategoryListPage />, index: true },
      //     { path: 'new', element: <CategoryCreatePage /> },
      //     { path: 'edit/:id', element: <CategoryEditPage /> },
      //   ],
      // },

      // {
      //   path: 'carousel',
      //   permissions: ['Carousels'],
      //   children: [
      //     { element: <CarouselListPage />, index: true },
      //     { path: 'new', element: <CarouselCreatePage /> },
      //     { path: 'edit/:id', element: <CarouselEditPage /> },
      //     // { path: ':id', element: <InvoiceDetailsPage /> },
      //   ],
      // },
      {
        path: 'meet',
        children: [
          { element: <ListMeets />, index: true },
          { path: 'new', element: <NewMeet /> },
          { path: 'edit/:id', element: <EditMeetPage /> },
          { path: 'edit-user/:id', element: <EditMeetPage /> },
        ],
      },
      // {
      //   path: 'company',
      //   permissions: ['Empresas'],
      //   children: [
      //     { element: <UserProfilePage />, index: true },
      //     { path: 'profile', element: <UserProfilePage /> },
      //     { path: 'cards', element: <UserCardsPage /> },
      //     { path: 'list', element: <UserListPage /> },
      //     { path: 'new', element: <UserCreatePage /> },
      //     { path: 'edit/:id', element: <UserEditPage /> },
      //     { path: 'account', element: <UserAccountPage /> },
      //   ],
      // },
      // {
      //   path: 'product',
      //   permissions: ['Productos'],
      //   children: [
      //     { element: <ProductListPage />, index: true },
      //     { path: 'list', element: <ProductListPage /> },
      //     { path: ':id', element: <ProductDetailsPage /> },
      //     { path: 'new', element: <ProductCreatePage /> },
      //     { path: 'edit/:id', element: <ProductEditPage /> },
      //   ],
      // },
      // {
      //   path: 'blog',
      //   permissions: ['Blogs'],
      //   children: [
      //     { element: <BlogPostsPage />, index: true },
      //     { path: 'list', element: <BlogPostsPage /> },
      //     { path: ':slug', element: <BlogPostPage /> },
      //     { path: 'edit/:slug', element: <BlogEditPostPage /> },
      //     { path: 'new', element: <BlogNewPostPage /> },
      //   ],
      // },
      {
        path: 'permission',
        permissions: ['Roles'],
        children: [
          { element: <RoleListPage />, index: true },
          {
            path: 'list',
            element: <RoleListPage />,
          },
          {
            path: 'new',
            element: <RoleCreatePage />,
          },
          {
            path: 'edit/:id',
            element: <RoleEditPage />,
          },
        ],
      },
      // {
      //   path: 'order',
      //   children: [
      //     { element: <OrderListPage />, index: true },
      //     { path: 'list', element: <OrderListPage /> },
      //     { path: ':id', element: <OrderDetailsPage /> },
      //   ],
      // },
      // {
      //   path: 'invoice',
      //   children: [
      //     { element: <InvoiceListPage />, index: true },
      //     { path: 'list', element: <InvoiceListPage /> },
      //     { path: ':id', element: <InvoiceDetailsPage /> },
      //     { path: 'edit/:id', element: <InvoiceEditPage /> },
      //     { path: 'new', element: <InvoiceCreatePage /> },
      //   ],
      // },

      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: 'edit/:id', element: <JobEditPage /> },
        ],
      },
      // {
      //   path: 'tour',
      //   children: [
      //     { element: <TourListPage />, index: true },
      //     { path: 'list', element: <TourListPage /> },
      //     { path: ':id', element: <TourDetailsPage /> },
      //     { path: 'new', element: <TourCreatePage /> },
      //     { path: 'edit/:id', element: <TourEditPage /> },
      //   ],
      // },
      // { path: 'file-manager', element: <FileManagerPage /> },
      // { path: 'mail', element: <MailPage /> },
      // { path: 'chat', element: <ChatPage /> },
      {
        path: 'calendar',
        permissions: ['Calendario'],
        children: [{ element: <CalendarPage />, index: true }],
      },
      // { path: 'kanban', element: <KanbanPage /> },
      // { path: 'permission', element: <PermissionDeniedPage /> },
      // { path: 'blank', element: <BlankPage /> },
    ],
  },
];
